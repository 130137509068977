@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=PT+Sans:wght@400;700&display=swap');
body {
  font-family: 'PT Sans', sans-serif;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul, ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

input:focus, input:active {
  box-shadow: none !important;
}

img {
  max-width: 100%;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

section {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 575.98px) {
  section {
    padding: 1.25rem 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  section {
    padding: 1.875rem 0;
  }
}

.container {
  max-width: 1170px;
}

.btn4 {
  width:"425px";
  background-color: "#F9CA26";
  color: "black";
  border: 0;
}
.pic4 {
  width: 120px;
}

/* @media screen and (max-width: 575px) {
  .btn4{
    width:200px
  }
  .pic4 {
    margin-left:30%
  }
} */
@media screen and (max-width: 575px) {
  .container {
    padding: 0px 15px;
    max-width: 100%;
    width: 100%;
  }
}

.text-mute {
  color: #787a7d !important;
}

html {
  scroll-behavior: smooth;
}

.section-title {
  text-align: center;
  font-family: 'El Messiri', sans-serif;
}
.section-title span {
  background-color: rgba(66, 82, 249, 0.2);
  padding: 6px 20px;
  border-radius: 130px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #4252f9;
}
.section-title h2 {
  font-weight: 600;
  margin: 15px 0px;
}
.section-title p {
  max-width: 600px;
  margin: auto;
}

header {
  padding-top: 2px;
}
header .head-ro {
  padding: 3px;
}
header .head-ro .logo img {
  max-width: 100px;
  padding-top: 3px;
  font-family: 'El Messiri', sans-serif;
}
header .head-ro .show {
  display: block !important;
}
@media only screen and (max-width: 767px) {
  header .head-ro .nav {
    display: none;
  }
}
header .head-ro .nav ul {
  width: 100%;
}
header .head-ro .nav ul li {
  float: left;
  padding: 10px;
}
@media only screen and (max-width: 860px) {
  header .head-ro .nav ul li {
    padding: 20px 12px;
  }
}
header .head-ro .nav ul li a {
  font-weight: 550;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
}

@media only screen and (max-width: 767px) {
  header .head-ro .nav ul li {
    float: none;
    text-align: right;
  }
}
header .head-ro .nav ul .btnll {
  float: right;
}
header .head-ro .nav ul .btnll .btn {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  header .head-ro .nav ul .btnll .btn {
    width: 100%;
  }
}

.scroll-to-fixed-fixed {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: rgb(26,27,40);
}

.head-cover {
  margin-top: 0;
  background-color: #1A1B28;
}
.head-cover a{
  text-decoration: none !important;
}

.head-cover ul li a{
  text-decoration: none !important;
}

.small-menu {
  float: right;
  padding: 10px;
  padding-top: 16px;
  font-size: 1.4rem;
}
.small-menu i {
  color: #ffffff;
}

.banner-container {
  background-color: #f3f4f8;
  padding-bottom: 100px;
}
.banner-container .banner-row .banner-txt {
  margin: auto;
}
.banner-container .banner-row .banner-txt h1 {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 3rem;
  font-family: 'El Messiri', sans-serif;
}
.banner-container .banner-row .banner-txt p {
  margin-bottom: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}
.banner-container .banner-row .btn-row {
  margin-top: 50px;
}
.banner-container .banner-row .btn-row .btn {
  width: 200px;
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  .banner-container .banner-row .btn-row .btn {
    width: 100%;
    margin-bottom: 5px;
  }
}
.clr:hover
{
background-color:rgb(195, 131, 28) !important;
color: #000000 !important;
}
.banner-container .banner-img {
  text-align: center;
}
.banner-container .banner-img img {
  max-width: 400px;
}
@media only screen and (max-width: 767px) {
  .banner-container .banner-img img {
    width: 100%;
  }
}

.key-featurecont {
  position: absolute;
  z-index: 999;
}
.key-featurecont .key-ro {
  margin-top: -52px;
  z-index: 999;
}
.key-featurecont .key-ro .keycol .key-cover {
  box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  background-color: #FFF;
  display: flex;
  padding: 15px;
  border-radius: 8px;
}
.key-featurecont .key-ro .keycol .key-cover .logo-cc img {
  max-width: 75px;
}
.key-featurecont .key-ro .keycol .key-cover .text-cc {
  flex-grow: 1;
  margin: auto 0px;
  padding-left: 15px;
}
.key-featurecont .key-ro .keycol .key-cover .text-cc h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.key-featurecont .key-ro .keycol .key-cover .text-cc p {
  font-size: 0.9rem;
}
@media only screen and (max-width: 767px) {
  .key-featurecont .keycol {
    margin-bottom: 10px;
  }
}

.about-coo {
  background-color: #FFF;
  padding-top: 130px;
}
.about-coo .about-row {
  margin-top: 50px;
}
@media only screen and (max-width: 770px) {
  .about-coo {
    padding-top: 300px;
    padding-bottom: 50px;
  }
}
.about-coo .about-row .about-img {
  text-align: center;
  margin: auto;
}
.about-coo .about-row .about-img img {
  max-width: 80%;
}
@media only screen and (max-width: 767px) {
  .about-coo .about-row .about-img img {
    width: 100%;
  }
}
@media only screen and (max-width: 476px) {
  .about-coo .about-row .about-img img {
    margin-bottom: 30px;
  }
}
.about-coo .about-row .about-text h1 {
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 40px;
}
.about-coo .about-row .about-text p {
  margin-bottom: 30px;
}
.about-coo .about-row .about-text .about-featur ul {
  width: 100%;
  margin-left: -10px;
}
.about-coo .about-row .about-text .about-featur ul li {
  padding: 10px;
  width: 50%;
  float: left;
  font-weight: 400;
}
.about-coo .about-row .about-text .about-featur ul li i {
  margin-right: 5px;
}

.page-nav {
  background-size: 100%;
  padding: 68px;
  text-align: center;
  padding-top: 60px;
  background-color: #f3f4f8;
}
.page-nav ul {
  float: none;
  margin: auto;
  width: auto;
  margin-top: 15px;
}
@media screen and (max-width: 992px) {
  .page-nav {
    background-size: auto;
  }
}
@media screen and (max-width: 767px) {
  .page-nav {
    padding-top: 100px;
  }
}
@media screen and (max-width: 356px) {
  .page-nav {
    padding-top: 200px;
  }
}
.page-nav h2 {
  font-size: 30px;
  width: 100%;
  font-weight: 600;
  color: #444;
}
@media screen and (max-width: 600px) {
  .page-nav h2 {
    font-size: 26px;
  }
}
.page-nav ul li {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-size: 0.93rem;
  font-weight: 600;
  color: #444;
}
.page-nav ul li i {
  width: 30px;
  text-align: center;
  color: #444;
}
.page-nav ul li a {
  color: #444;
  font-size: 0.93rem;
  font-weight: 600;
}

/* ===================================== Our Team Style  ================================== */
.our-team {
  padding: 50px;
}

@media screen and (max-width: 940px) {
  .our-team {
    padding: 50px 10px;
  }
}
.our-team .team-row {
  margin-top: 20px;
}

.our-team .team-row .single-usr {
  box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54);
  margin-bottom: 20px;
}

.our-team .team-row .single-usr img {
  width: 100%;
}

.our-team .team-row .single-usr .det-o {
  padding: 20px;
  background-color: #0363b0;
}

.our-team .team-row .single-usr .det-o h4 {
  color: #FFF;
  margin-bottom: 0px;
}

.our-team .team-row .single-usr .det-o i {
  color: #FFF;
}

.cont-row {
  margin-bottom: 20px;
}

.testimonial-container {
  background-color: #f3f4f8;
  padding: 50px;
}

@media screen and (max-width: 940px) {
  .testimonial-container {
    padding: 50px 10px;
  }
}
.testimonial-container .float-auto {
  float: none;
  margin: auto;
}

.testimonial {
  padding: 90px 5px 50px;
  margin: 50px 30px 30px;
  height: 300px;
  /* border: 1px solid #F9CA26; */
  background-color: #1A1B28;
  box-shadow: 4.871px 10px 8px 0px rgba(0, 0, 0, 0.06);
  position: relative;
}

.testimonial .pic {
  padding:20px;
  background-color:#1A1B28;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #F9CA26;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
}

.testimonial .pic img {
  width: 100%;
  height: auto;
}

.testimonial .description {
  font-size: 15px;
  color: white;
  line-height: 27px;
  text-align: center;
  margin: 0;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}

/* .testimonial .description:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 25px;
  color: #007bfc;
  position: absolute;
  top: 0;
  left: -2px;
} */

.testimonial .title {
  display: inline-table;
  padding: 10px;
  margin: 0 auto;
  background: #1A1B28;
  border: 1px solid #F9CA26;
  font-size: 10px;
  font-weight: 700;
  color: #F9CA26;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  cursor: pointer;
}

.testimonial .post {
  font-size: 15px;
  color: #F9CA26;
}

.owl-theme .owl-controls {
  width: 100%;
  height: 50px;
  opacity: 1;
  position: absolute;
  top: 38%;
}

.owl-theme .owl-controls .owl-buttons div {
  width: 50px;
  height: 50px;
  line-height: 40px;
  border-radius: 0;
  background: transparent;
}

.owl-prev {
  position: absolute;
  left: -5%;
}

.owl-next {
  position: absolute;
  right: -5%;
}

.owl-prev:after,
.owl-next:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 30px;
  color: #F9CA26;
  opacity: 1;
}

.owl-next:after {
  content: "";
}

@media only screen and (max-width: 479px) {
  .testimonial {
    padding: 70px 10px 30px;
  }

  .testimonial .description:before {
    top: -20px;
  }

  .testimonial .title {
    font-size: 12px;
  }

  .testimonial .post {
    font-size: 11px;
  }
}
.no-margin {
  margin: 0px;
}

/* =================== Blog Style Starts Here =================== */
.blog {
  padding: 50px;
  background-color: #ffffff;
}

@media screen and (max-width: 990px) {
  .blog {
    padding: 0px 10px;
    padding-top: 40px;
  }
}
@media screen and (max-width: 1199px) {
  .blog .container {
    text-align: center;
    max-width: 100%;
  }
}
.blog .blog-singe {
  /* box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54); */
  border-radius: 2px;
  background-color: #FFF;
  margin-bottom: 30px;
}

.blog .blog-singe .blog-img-tab {
  background-color: rgb(26,27,40);
  padding: 10px;
}

.blog .blog-singe .blog-content-tab {
  padding: 15px;
  color: white;
  background-color: rgb(26,27,40);
}

.blog .blog-singe .blog-content-tab h2 {
  font-size: 19px;
}

.blog .blog-singe .blog-content-tab i {
  margin-right: 10px;
  font-size: 12px;
}

.blog .blog-singe .blog-content-tab i small {
  margin-left: 5px;
  font-size: 100%;
}

.blog .blog-singe .blog-content-tab .blog-desic {
  margin-top: 8px;
  font-size: 0.9rem;
  text-indent: 20px;
  margin-bottom: 10px;
}

.blog .blog-singe .blog-content-tab a {
  color: #007bfc;
  font-size: 12px;
  font-weight: 600;
}

.blog .blog-singe .blog-content-tab a i {
  margin-left: 15px;
  font-size: 13px;
}

.blog-row {
  margin-top: 20px;
}

.features {
  padding: 50px;
  background-color: #f3f4f8;
}
@media screen and (max-width: 940px) {
  .features {
    padding: 30px 10px;
  }
}
.features .feature-row {
  margin-top: 30px;
}
.features .feature-row .feature-col {
  border-radius: 10px;
  text-align: center;
  background-color: #FFF;
  padding: 30px;
  height: 400px;
  margin-bottom: 30px;
  box-shadow: 0 2px 3px 0 rgba(218, 218, 253, 0.35), 0 0px 3px 0 rgba(206, 206, 238, 0.35);
}
.features .feature-row .feature-col img {
  margin-bottom: 15px;
}
.features .feature-row .feature-col i {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #28a745;
}
.features .feature-row .feature-col:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.features .feature-row .feature-col h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 12px;
}
.features .feature-row .feature-col p {
  font-size: 0.9rem;
}

.more-features {
  padding: 50px;
  background-color: #fff;
}
@media screen and (max-width: 940px) {
  .more-features {
    padding: 30px 10px;
  }
}
.more-features .more-featur-ro {
  margin-top: 20px;
}
.more-features .more-featur-ro .more-feat {
  display: flex;
  margin-bottom: 30px;
}
.more-features .more-featur-ro .more-feat .icon {
  padding: 15px;
}
.more-features .more-featur-ro .more-feat .icon img {
  width: 110px;
}
.more-features .more-featur-ro .more-feat .detail {
  padding: 15px;
}

/* ===================================== Footer Cover Style  ================================== */
.footer-ablove {
  padding: 30px;
  background-color: #007bfc;
  color: #FFF;
  font-size: 1.5rem;
}

.footer-ablove .btn {
  float: right;
  background-color: #FFF;
  font-family: "mouse-500", Arial, Helvetica, sans-serif;
}

footer {
  padding: 80px 50px;
  background-color: #0d1e67;
  color: #FFF;
}

@media screen and (max-width: 940px) {
  footer {
    padding: 50px 10px;
  }
}
footer h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

footer .about p {
  font-size: 0.9em;
  margin-bottom: 20px;
}

footer .about .foot-address {
  display: flex;
}

footer .about .foot-address .icon {
  padding: 20px 10px;
}

footer .about .foot-address .addet {
  font-size: 0.9rem;
  margin: auto 0px;
}

footer .fotblog .blohjb {
  margin-bottom: 10px;
}

footer .fotblog .blohjb p {
  font-size: 0.9rem;
}

footer .fotblog .blohjb span {
  font-size: 0.9rem;
}

footer .glink ul li {
  padding: 10px;
}

footer .glink ul li i {
  margin-right: 10px;
}

footer .glink ul li a {
  color: #FFF;
}

footer .tags ul li {
  float: left;
  padding: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid #CCC;
}

.copy {
  padding: 20px;
  background-color: rgb(23, 24, 32);
  color: #FFF;
}

.copy a {
  color: #FFF;
}

.copy span {
  float: right;
}

.copy span i {
  margin-right: 20px;
}
@media screen and (max-width: 560px) {
  .copy {
    padding-bottom: 40px;
  }
}

.cop-ck {
  padding: 20px;
  background-color: #ffffffbd;
}
.cop-ck h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.modal-style {
  color:white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height:350px;
  border: 1px solid rgb(80, 80, 80);
  background-color: rgb(38,39,52);
  border-radius:30px;
  box-shadow: 24px;
  text-align:center;
  padding-bottom: 20px;
}
.modal-btn {
  padding: 10px;
  border: 0;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(38,39,52);
}
@media screen and (max-width: 670px ) {
  .modal-style {
    width: 300px;
    height: 400px;
  }
  .modal-btn {
    padding: 8px;
  }
}

/* innovative startups */

.startups {
  padding: 90px 5px 50px;
  margin: 50px 40px 70px;
  width: 300px;
  height: 300px;
  /* border: 1px solid #F9CA26; */
  background-color: #1A1B28;
  box-shadow: 4.871px 10px 8px 0px rgba(0, 0, 0, 0.06);
  position: relative;
}

.startups .pic2 {
  padding:10px;
  background-color:#ffffff;
  width: 100px;
  height: 100px;
  border-radius: 20%;
  border: 5px solid #F9CA26;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
}

.startups .description2 {
  font-size: 15px;
  color: white;
  line-height: 27px;
  text-align: center;
  margin: 0;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}

.startups .btn2 {
  display: inline-table;
  padding: 10px;
  margin: 0 auto;
  background: #1A1B28;
  border: 1px solid #F9CA26;
  font-size: 10px;
  font-weight: 700;
  color: #F9CA26;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  cursor: pointer;
}

.startups .btn2:hover {
  background-color: #F9CA26;
  color: black;
}

/* explore startups */

/* .explore {
  padding: 140px 10px 50px;
  margin: 70px 50px 70px;
  width: 90%;
  background-color: #1A1B28;
  box-shadow: 4.871px 10px 8px 0px rgba(0, 0, 0, 0.06);
  position: relative;
}

.explore .pic3 {
  padding:20px;
  background-color:#ffffff;
  width: 200px;
  height: 200px;
  border-radius: 20%;
  border: 5px solid #F9CA26;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
}
.explore .email {
  font-size: small;
  color: rgb(173, 173, 173);
  float: right;
  overflow: hidden;
  margin: 10px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}

.explore .description3 {
  font-size: 15px;
  color: rgb(173, 173, 173);
  line-height: 27px;
  margin: 0;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}


@media screen and (max-width: 670px ){
  .explore {
    margin-top: 120px;
    width: 300px;
  }
  .explore .pic3 {
    width: 150px;
    height: 150px;
  }
} */

/* login */

.login {
  padding: 50px;
  margin-left: 200px;
  margin-bottom: 50%;
}

@media screen and (max-width: 670px ){
  .login{
    margin-top: 100px;
    margin-bottom: 100%;
    margin-left: 10px;
    padding: 20px;
  }
}

/* buy */
.buy {
  padding: 50px;
}
.buy .details{
  padding: 20px;
}
.buy .order {
   margin-left: 20px;
   padding: 20px;
   font-size: 20px;
}
.buy .btn {
  width: 450px;
  background-color: #F9CA26;
  color: black;
  border: 0,
}
@media screen and (max-width: 670px ){
  .buy{
    padding: 20px;
  }
  .buy .details {
    padding: 0;
    padding-bottom: 50px;
  }
  .buy .order {
    margin-left: 0;
    padding: 0;
  }
  .buy .btn {
    margin: 10px;
    width: 300px;
  }
  .buy img {
    margin-left: 110px;
  }
}
/* portfolio */
.portfolio {
  padding: 20px;
  margin-bottom: 400px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  text-align: left;
  padding: 8px;
  background-color: rgb(26,27,40);
}

tr:nth-child(even) {
  background-color: rgb(26,27,40);
}

@media screen and (max-width: 670px ){
  .portfolio{
    padding: 0;
  }
  .portfolio table {
    width: 250px;
  }
}
/*# sourceMappingURL=style.css.map */
